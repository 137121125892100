module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-65110817-4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#663399","display":"minimal-ui","icon":"src/assets/icon.png","name":"Design Studios","short_name":"Design Studios","start_url":"/","theme_color":"#663399"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
